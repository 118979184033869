import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChildCard from './ChildCard';
import GetStartedCard from './GetStartedCard';
import InfoCard from './InfoCard';
import SockImage from '../../assets/info_product_sock.png';
import NavHeaderHome from '../../components/NavHeader/NavHeaderHome';
import theme from '../../design/theme';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';
import DesktopBanner from '../../components/DesktopBanner';
import StagingBanner from '../../components/StagingBanner';
import EOLBanner from '../../components/EOLBanner';
import ChildProfile from '../Profile/ChildProfile';
import { details } from '../../navigation/routes';
import { useAccountContext } from '../../../backend/contexts/accountContext';
import useFeatureFlag from '../../../backend/hooks/useFeatureFlag';
import { FeatureFlags } from '../../../shared/constants';

const homeGrid = {
  height: '100%',
  overflowY: 'scroll' as const,
};
const homeInfoArea = {
  height: '100%',
  background: theme.color.background.darkMode.darker,
  overflowY: 'scroll' as const,
};
const homeInfoAreaGradient = {
  height: '100%',
  background: 'linear-gradient(#9F9FDC 0%, #19232F 30%)',
  overflowY: 'scroll' as const,
};

const Home = () => {
  const navigate = useNavigate();
  const { accountUser, accountUserStatus } = useAccountContext();
  const showStagingBanner = useFeatureFlag(FeatureFlags.STAGING_BANNER);
  const showEOLBanner = useFeatureFlag(FeatureFlags.EOL_BANNER);
  const { serviceUsersList } = useServiceUserContext();
  const { t } = useTranslation();
  const { firstName } = accountUser;

  useEffect(() => {
    if (firstName === '' && accountUserStatus === 'success') {
      navigate(details());
    }
  }, [accountUserStatus, firstName, navigate]);

  const renderHome = () => {
    if (serviceUsersList.length === 0) {
      return (
        <div style={homeInfoAreaGradient}>
          <GetStartedCard />
          <InfoCard ImageUrl={SockImage} title={t('Learn more about the Owlet Smart Sock')} />
        </div>
      );
    }

    if (serviceUsersList.length === 1) {
      return (
        <ChildProfile
          serviceUserKey={serviceUsersList[0].id}
        />
      );
    }

    return (
      <div style={homeInfoArea}>
        <NavHeaderHome />
        {serviceUsersList.map((serviceUser) => (
          <ChildCard
            key={`home-child-card-${serviceUser.id}`}
            serviceUser={serviceUser}
            service={serviceUser.service}
          />
        ))}
      </div>
    );
  };

  return (
    <div style={homeGrid}>
      {showStagingBanner ? <StagingBanner /> : null}
      {showEOLBanner ? <EOLBanner /> : null}
      <DesktopBanner />
      {renderHome()}
    </div>
  );
};

export default Home;
